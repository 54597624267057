
import {Getter, State} from "vuex-class";
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import DateInput from "@/components/shared/DateInput.vue";
import TextInput from "@/components/shared/TextInput.vue";
import SubSection from "@/components/shared/SubSection.vue";
import CardSection from "@/components/shared/CardSection.vue";
import SaveToolbar from "@/components/shared/SaveToolbar.vue";
import SelectInput from "@/components/shared/SelectInput.vue";
import NumberInput from "@/components/shared/NumberInput.vue";
import CheckboxInput from "@/components/shared/CheckboxInput.vue";
import {SaveableSection, SaveProvider, SaveResult} from "@/types";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import TextAreaInput from "@/components/shared/TextAreaInput.vue";
import SelectOtherInput from "@/components/shared/SelectOtherInput.vue";
import {calculateAge } from "@/utils";
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import {
  Recipient,
  RecipientFinancialAssistance,
  RecipientFinancialAttachment,
  RecipientProfileDetails,
  RecipientValidations
} from "@/store/recipients/types";
import {
  Ethnicity,
  Gender,
  InsurancePlanCode,
  InsuranceType,
  OrganCodeValue,
  Province,
  TperStatusCode
} from "@/store/lookups/types";
import {RecipientJourney} from "@/store/recipientJourney/types";
import { SystemModules } from '@/store/features/types';

interface DemographicsForm {
  internal: {
    client_id?: number;
    ctr_id?: number;
    registration_date?: string;
    registration_time?: string;
    recipient_type?: string;
    urgent?: boolean;
  };
  personal: {
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    date_of_birth?: string;
    sex?: string;
    gender?: string | null;
    gender_sex_different?: boolean;
    gender_other?: string | null;
    ethnicity?: string | null;
    ethnicity_other?: string;
    insurance_type?: number;
    insurance_other?: number;
    insurance_number?: string;
    insurance_province?: string;
    in_utero?: boolean|null;
  };
  financial_assistance: {
    status?: string;
    tper_date?: string;
    tper_comment?: string;
    tper_attachments?: FileList;
    tper_applicable?: boolean;
    uploadedFiles?: RecipientFinancialAttachment[];
  };
}

interface ReferralDetailsForm {
  organCode: string|null;
  mrn: string|undefined;
  urgent: boolean;
  transplant: {
    transplantProgram?: string;
    recipientCoordinator?: string;
  };
  referralPackage: {
    referredWith: number|null,
  };
}

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    CardSection,
    SaveToolbar,
    SelectInput,
    NumberInput,
    CheckboxInput,
    SelectOtherInput,
    TextAreaInput,
    BooleanRadioInput
  }
})
export default class Demographics extends mixins(DateUtilsMixin) implements SaveableSection {
  // State
  @State(state => state.lookups.province) provinceLookup!: Province[];
  @State(state => state.lookups.ethnicity) ethnicityLookup!: Ethnicity[];
  @State(state => state.pageState.currentPage.demographics) editState!: DemographicsForm;
  @State(state => state.pageState.currentPage.organDetails) organDetailsState!: ReferralDetailsForm;
  @State(state => state.lookups.insurance_plan_codes) insurancePlanCodeLookup!: InsurancePlanCode[];
  @State(state => state.recipients.selectedRecipient.validations) validations!: RecipientValidations;
  @State(state => state.lookups.gender) genderLookup!: Gender[];
  @State(state=> state.lookups.tper_status) tperStatusLookup!: TperStatusCode[];

  // Getters
  @Getter('isUrgent', { namespace: 'recipients' }) public isUrgent!: boolean;
  @Getter('getDobMinDateLimit', { namespace: 'lookups' }) private getDobMinDateLimit!: string;
  @Getter('getDobMaxDateLimit', { namespace: 'lookups' }) private getDobMaxDateLimit!: string;
  @Getter("show", { namespace: "recipients" }) private recipient!: Recipient;
  @Getter("insurance", { namespace: "lookups" }) private insuranceLookup: any;
  @Getter("sexOptions", { namespace: "lookups" }) sexOptions!: boolean;
  @Getter("financialAttachments", { namespace: "recipients" }) private financialAttachments!: { name?: string, id?: string }[] | undefined;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;
  @Getter('mustBeBlank', { namespace: "validations" }) private mustBeBlank!: (ruleKey: string) => boolean;
  @Getter('defaultLookup', { namespace: 'lookups' }) defaultLookup!: (lookupId: string) => any;
  @Getter('checkPropExists', { namespace: 'validations' }) checkPropExists!: (ruleKey: string) => boolean;
  @Getter('getMaskFormatType', { namespace: 'lookups' }) getMaskFormatType!: (code: number) => string;

  // Properties
  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = [
    "ethnicity",
    "gender",
    "insurance_plan_codes",
    "country",
    "tper_status"
  ];

  get showEthnicityOther(): boolean {
    return this.checkPropExists('patient_profile.ethnicity_other');
  }

  //Show financial assisstence if Heart, Lungs or Small Bowel
  public ORGAN_CODES_TO_INCLUDE =
    [
      OrganCodeValue.Lung,
      OrganCodeValue.Heart,
      OrganCodeValue.SmallBowel
    ];

  get isTperStatusDateRequired(): boolean {
    if (!this.editState || !this.editState.financial_assistance.status) {
      return false;
    }
    return true;
  }

   /**
   * Returns if the in_utero field must be displayed
   *
   * @returns {boolean} true if the in utero field is displayed
   */
  get displayInUtero(): boolean {
    const propertyExists = this.checkPropExists('patient_profile.in_utero');
    const journeys = this.recipient.journeys;
    const selectedOrganCode = this.organDetailsState ? this.organDetailsState.organCode : null;
    if (journeys) {
      const organs = journeys.map((journey: RecipientJourney) => {
        return journey.organ_code;
      });
     
      return propertyExists && (organs.includes(OrganCodeValue.Heart) || selectedOrganCode === OrganCodeValue.Heart.toString());
    }
    return false;
  }

  /**
   * Gets a boolean value representing if insurance type other
   *
   * @returns {boolean} true if insurance type other is selected, false otherwise
   */
  get insuranceTypeIsOther(): boolean {
    if (!this.editState || !this.editState.personal.insurance_type) {
      return false;
    }
    return (
      this.editState.personal.insurance_type == InsuranceType.Other
    );
  }

  get showOtherProvinceInsurance(): boolean {
    if (!this.editState.personal) {
      return false;
    }
    return (
      this.editState.personal.insurance_type == InsuranceType.OtherProvince
    );
  }

  /**
   * Get a number for the age of the Recipient
   *
   * Calculates the age of the Recipient using the value of Date of Birth and Date of Death (if exists)
   *
   * @returns {number|null} Recipient's age or null
   */

  get calculatedAge() {
    const recipientDoB = this.editState.personal.date_of_birth || null;
    const recipientDoD =
      this.recipient.patient_profile && this.recipient.death
        ? this.recipient.death.death_date
        : null;
    // I have a birth and death date
    if (recipientDoB && recipientDoD) {
      return calculateAge(recipientDoB, this.parseDateUi(recipientDoD));
    }
    // I have a birth date only
    if (recipientDoB && !recipientDoD) {
      return calculateAge(recipientDoB);
    }
    return null;
  }

  // Do we need to show the Insurance Number field?
  get showNumber(): boolean {
    if (!this.editState || !this.editState.personal) {
      return false;
    }

    if (this.editState.personal.insurance_number) return true;

    return !this.mustBeBlank('patient_profile.insurance.number');
  }

  // returns province dropdown without ontario for the 'other provinces' dropdown
  get otherProvinceDropdown(): Province[] {
    if (!this.provinceLookup) {
      return [];
    }
    const ontarioIndex = this.provinceLookup.findIndex( element => element.code === "ON");
    const provinceWithoutOntario = this.provinceLookup.filter((element, index) => {
        return index === ontarioIndex ? false : element;
      }
    );
    return provinceWithoutOntario;
  }

  // Is the Financial Assistance system module enabled?
  get isFinancialAssistanceEnabled(): boolean {
    return this.moduleEnabled(SystemModules.FINANCIAL_ASSISTANCE);
  }

  // Is Urgent Recipient system module enabled?
  get isUrgentRecipientEnabled(): boolean {
    return this.moduleEnabled(SystemModules.URGENT_RECIPIENT);
  }

  // Initialize the form
  @Watch('organDetailsState.organCode')
  public initializeForm(): void {
    this.$store.commit("pageState/set", {
      pageKey: "demographics",
      value: this.buildDemographicsForm(this.recipient)
    });
  }

  // Clear ethnicity other when ethnicity changes
  public clearEthnicityOther() {
    Vue.set(this.editState.personal, "ethnicity_other", undefined);
  }

  // Trim last name value on changed of Last Name field
  public trimLastName(field_name: string|any) {
    Vue.set(this.editState.personal, 'last_name', this?.editState?.personal?.last_name?.trim());
  }

  // Trim first name value on changed of First Name field
  public trimFirstName(field_name: string|any) {
    Vue.set(this.editState.personal, 'first_name', this?.editState?.personal?.first_name?.trim());
  }

  // Clear tper date when tper status changes
  public clearTPERDate() {
    Vue.set(this.editState.financial_assistance, "tper_date", undefined);
  }

  // Clear gender other when gender changes
  public clearGenderOther() {
    Vue.set(this.editState.personal, "gender_other", undefined);
  }

  // Clear insurance other when insurance changes
  public clearInsurance() {
    Vue.set(this.editState.personal, "insurance_number", undefined);
    Vue.set(this.editState.personal, "insurance_province", undefined);
    Vue.set(this.editState.personal, "insurance_other", undefined);
  }

  // Triggered when all the lookups have been loaded
  // NOTE: this ensure we initialize the form AFTER lookups are loaded by the sub-section
  public loaded(): void {
    this.initializeForm();
    this.$emit("loaded", "demographics");
  }

  // Translate from relevant parts of the recipient data structure to the form layout
  public buildDemographicsForm(recipient: Recipient): DemographicsForm {
    // Initialize empty form state with default values
    const result: DemographicsForm = {
      internal: {},
      personal: {
        insurance_type: this.defaultLookup('insurance_plan_codes'),
      },
      financial_assistance: {},
    };
    // Copy values from the recipient patient profile into the form state
    const recipientProfile = recipient.patient_profile || {};
    const genderSexDifferent = recipientProfile.gender_sex_different ? true : false;
    const insurancePlanCode = recipient.patient_profile!.insurance!.plan_code;
    Object.assign(result, {
      internal: {
        client_id: recipient.client_id,
        ctr_id: recipient.patient_profile?.ctr?.national_recipient_id,
        registration_date: this.parseDateUiFromDateTime(recipient.patient_profile!.created_at),
        registration_time: this.parseTimeUiFromDateTime(recipient.patient_profile!.created_at),
        recipient_type: undefined,
        urgent: recipient.urgent
      },
      personal: {
        first_name: recipient.patient_profile!.first_name,
        middle_name: recipient.patient_profile!.middle_name,
        last_name: recipient.patient_profile!.last_name,
        date_of_birth: this.parseDateUi(recipient.patient_profile!.birth!.date),
        sex: recipient.patient_profile!.sex || undefined,
        gender: genderSexDifferent ? recipientProfile.gender : null,
        gender_sex_different: recipient.patient_profile!.gender_sex_different,
        gender_other: recipient.patient_profile!.gender_other,
        ethnicity: recipient.patient_profile!.ethnicity_code || null,
        ethnicity_other: recipient.patient_profile!.ethnicity_other || undefined,
        insurance_type: insurancePlanCode != null ? insurancePlanCode : result.personal.insurance_type,
        insurance_province: recipient.patient_profile!.insurance!.other_province_plan || undefined,
        insurance_other: recipient.patient_profile!.insurance!.other_plan || undefined,
        insurance_number: recipient.patient_profile!.insurance!.number || undefined,
        in_utero: recipient.patient_profile!.in_utero || false
      },
      financial_assistance: {
        status: recipient.financial_assistance?.status,
        tper_date: this.parseDateUi(recipient.financial_assistance?.date),
        tper_comment: recipient.financial_assistance?.comments,
        tper_applicable: this.isFinancialAssistanceNeeded(),
        uploadedFiles: recipient.attachments
      }
    });
    return result;
  }

  // Translate from the form layout to relevant parts of the recipient data structure
  public extractPatch(): Recipient {
    const updatedProfile: RecipientProfileDetails = {
      first_name: this.editState.personal.first_name,
      middle_name: this.editState.personal.middle_name,
      last_name: this.editState.personal.last_name,
      in_utero: this.editState.personal.in_utero === undefined ? false : this.editState.personal.in_utero,
      birth: {
        date: this.sanitizeDateApi(this.editState.personal.date_of_birth)
      },
      sex: this.editState.personal.sex || null,
      gender_sex_different: this.editState.personal.gender_sex_different ? true : false,
      gender: (this.editState.personal.gender_sex_different && this.editState.personal.gender) || null,
      gender_other: (this.editState.personal.gender_sex_different && this.editState.personal.gender_other) || null,
      ethnicity_code: this.editState.personal.ethnicity || null,
      ethnicity_other: this.editState.personal.ethnicity_other || null,
      insurance: {
        plan_code: this.editState.personal.insurance_type,
        number: this.editState.personal.insurance_number || null,
        other_plan: this.editState.personal.insurance_other || null,
        other_province_plan: this.editState.personal.insurance_province || null
      }
    };

    const payload: any = {
      _id: this.recipient._id,
      urgent: this.editState.internal.urgent ? true : false,
      patient_profile: updatedProfile,
    };

    if (this.isFinancialAssistanceEnabled) {
      const financial_assistance: RecipientFinancialAssistance = {
        status: this.editState.financial_assistance.status,
        date: this.sanitizeDateApi(this.editState.financial_assistance.tper_date),
        comments: this.editState.financial_assistance.tper_comment,
        tper_applicable: this.editState.financial_assistance.tper_applicable
      };
      payload.financial_assistance = financial_assistance;
    }

    return payload;
  }

  public isFinancialAssistanceNeeded(): boolean {
    const journeys = this.recipient.journeys;
    if (journeys) {
      const organs = journeys.map((journey: RecipientJourney) => {
        return journey.organ_code;
      });
      const assisstanceNeeded = this.ORGAN_CODES_TO_INCLUDE.some(item =>
        organs.includes(item)
      );
      return assisstanceNeeded;
    }
    return false;
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const saveProvider = (this.$refs
      .saveDemographics as unknown) as SaveProvider;
    // Reset the save provider's save toolbar
    saveProvider.resetSaveToolbar();
  }



  // Handle saving triggered by local save button
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = (this.$refs
      .saveDemographics as unknown) as SaveProvider;
    // Report to parent that saving has began
    this.$emit("save", "demographics");
    // Generate payload based on current edit state
    const recipientPatch = this.extractPatch();
    // Dispatch save action and register the response
    this.$store
      .dispatch("recipients/saveRecipientPatch", recipientPatch )
      .then((success: SaveResult) => {
        if (this.editState.financial_assistance.tper_attachments) {
          this.$store.dispatch("recipients/uploadFile", {
              recipientId: this.recipient.client_id,
              fileList: this.editState.financial_assistance.tper_attachments,
              fileName: this.editState.financial_assistance.tper_attachments[0].name,
              id: null
            })
            .then((result: SaveResult) => {
              this.$store.dispatch("recipients/addUploadedFile", {
                attachment: result.responseData.attachment
              });
              (this.$refs.fileUploader as any).value = null;
            })
            .catch((error: SaveResult) => {
              this.$emit("handleErrors", error);
              saveProvider.registerSaveResult(error);
            });
        } else {
        }
        // If successful, update the current recipient and show success notification
        this.$store.commit("recipients/set", success.responseData.recipient);
        saveProvider.registerSaveResult(success);
        this.initializeForm();
      })
      .catch((error: SaveResult) => {
        // Emit event to handle errors
        this.$emit("handleErrors", error);
        saveProvider.registerSaveResult(error);
      });
  }

  public generateDownloadLink(id: string): void {
    const payload = {
      recipientId: this.recipient.client_id,
      id: id,
    };

    this.$store.dispatch('recipients/downloadFile', payload).then((result: any) => {
      const link = document.createElement('a');
      link.href = result;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
    }).catch((error: any) => {
        console.warn(this.$t('error_generating').toString(), error);
    });
  }

   /**
     * Handle deleting a file after the user accepts a warning dialog.
     */
    private onDeleteFile(id: string, name:string): void {
      const confirmed = confirm(this.$t('are_you_sure').toString() + "[" + name + "] ?");
      if (!confirmed) {
        // Cancel save if not confirmed
        return;
      }
      this.$store.dispatch('recipients/deleteFile', {
        id: id,
        recipientId: this.recipient.client_id,
      });
    }

  /**
   * Updates form state when Clinical Attachments files are uploaded
   *
   * @listens tper_attachments#changed
   */
  private onTperAttachmentsChanged(event: any) {
    if (!!this.editState && !!event.target) {
      {
        this.editState.financial_assistance.tper_attachments = event.target.files;

        if (this.editState.financial_assistance.tper_attachments && this.editState.financial_assistance.tper_attachments[0]) {
          Vue.set(this.editState, "fileName", this.editState.financial_assistance.tper_attachments[0].name);
        } else {
          Vue.set(this.editState, "fileName", "");
        }
      }
    }
  }

  // API response keys on the left, id for our UI on the right
  public get idLookup(): {[key: string]: string} {
    const result = {
      "patient_profile.first_name"                      : "demographics-personal-firstname",
      "patient_profile.last_name"                       : "demographics-personal-lastname",
      "patient_profile.insurance.number"                : "demographics-personal-insurancenumber",
      "patient_profile.sex"                             : "demographics-internal-sex",
      "patient_profile.gender_sex_different"            : "demographics-personal-gender-different",
      "patient_profile.ethnicity_code"                  : "ethnicity_code",
      "patient_profile.ethnicity_other"                 : "demographics-personal-ethnicityother",
      "patient_profile.gender"                          : "demographics-personal-gender",
      "patient_profile.gender_other"                    : "demographics-personal-other-gender",
      "patient_profile.insurance.plan_code"             : "plan_code",
      "patient_profile.birth.date"                      : "demographics-personal-dob",
      "patient_profile.insurance.other_plan"            : "demographics-personal-insuranceother",
      "patient_profile.insurance.other_province_plan"   : "demographics-personal-provinceother",
      "patient_profile.in_utero"                        : "demographics-personal-in-utero",
      "financial_assistance.date"                       : "demographics-fa-tper_date",
      "financial_assistance.status"                     : "demographics-fa-tper_status",
      "financial_assistance.comments"                   : "demographics-fa-tper_comment",
      

       file: "demographics-fa-tper-attachment"
    };
    return result;
  }
}
